


















































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import TextInput from '@/app/ui/components/TextInput/index.vue'
import DropdownSelect from '@/app/ui/components/DropdownSelect/index.vue'
import controller from '@/app/ui/controllers/ShuttleController'
import manageCourierController from '@/app/ui/controllers/ManageCourierController'
import DataTableV2 from '@/app/ui/components/DataTableV2/index.vue'
import PaginationNav from '@/app/ui/components/PaginationNav/index.vue'
import {
  IHeaderCell,
  ITableCell,
} from '@/data/infrastructures/misc/interfaces/shuttleManagement'
import { Utils } from '@/app/infrastructures/misc'
import LoadingOverlay from '@/app/ui/components/LoadingOverlay/index.vue'
import { ManageCourier } from '@/domain/entities/ManageCourier'
import Button from '@/app/ui/components/Button/index.vue'
import CheckBox from '../../components/CheckBox/index.vue'
import {
  CourierSortFields,
  CourierSortType,
} from '@/app/infrastructures/misc/Constants/cancelValidation'
import AscendingIcon from '@/app/ui/assets/ascending_icon.vue'
import ModalConfirm from '../../components/Modals/ModalConfirm/index.vue'
import routeController from '@/app/ui/controllers/RouteController'
import { LetterCode } from '@/domain/entities/Route'

interface IOption {
  label: string
  value: string | number
}

interface IParameters {
  page: number
  perPage: number
  search: string
  letterCode: IOption | null
}

@Component({
  components: {
    TextInput,
    DropdownSelect,
    DataTableV2,
    PaginationNav,
    LoadingOverlay,
    Button,
    CheckBox,
    ModalConfirm,
    AscendingIcon,
  },
})
export default class ShuttleFormAddCourier extends Vue {
  controller = controller
  manageCourierController = manageCourierController
  routeController = routeController

  enumSelectedSort = CourierSortType
  enumSortFields = CourierSortFields

  selectedSort: CourierSortType | null = null

  letterCodeOptions: IOption[] = [
    {
      label: 'Semua',
      value: '',
    },
  ]

  listData: ITableCell[][] = []

  perPageOptions: IOption[] = [
    { label: '10', value: 10 },
    { label: '50', value: 50 },
    { label: '100', value: 100 },
  ]

  parameters: IParameters = {
    page: 1,
    perPage: 10,
    search: '',
    letterCode: this.letterCodeOptions[0],
  }

  isModalConfirmVisible = false

  headers = [
    this.headerCellMapper('', '92px'),
    this.headerCellMapper('Kurir ID', '100px'),
    this.headerCellMapper('Nama Kurir', '158px'),
    this.headerCellMapper('No. Handphone', '182px'),
    this.headerCellMapper('Jenis Kurir', '120px'),
    this.headerCellMapper('POS', '250px'),
    this.headerCellMapper('3LC', '80px'),
    this.headerCellMapper('Atur', '120px'),
  ]

  created(): void {
    this.fetchCourierList(true)
    this.routeController.getLetterCodes()

    window.addEventListener('scroll', this.setFloatingShadow)

    if (this.isEditPage) {
      this.controller.setIsEditAddCourier(true)
    }
  }

  get params(): Record<string, string | number> {
    return {
      page: this.parameters.page,
      perPage: this.parameters.perPage,
      q: this.parameters.search,
      driverType: 'KVP',
      courierStatus: 'ACTIVE',
      sortColumn: this.selectedSort ? this.selectedSort.split('-')[0] : '',
      sortBy: this.selectedSort ? this.selectedSort.split('-')[1] : '',
      origin: this.parameters.letterCode
        ? this.parameters.letterCode.value
        : '',
    }
  }

  get isEditPage(): boolean {
    return this.$route.name === 'ShuttleManagementEditAddCourier'
  }

  public fetchCourierList(reset = false): void {
    if (reset) {
      this.parameters.page = 1
    }

    this.manageCourierController.getManageCourierList(this.params)
  }

  public onSearch = Utils.debounce(() => {
    this.fetchCourierList(true)
  }, 400)

  public onFilter(value: IOption | null): void {
    if (!value) {
      this.parameters.letterCode = this.letterCodeOptions[0]
    }

    this.fetchCourierList(true)
  }

  public onCheckAll(): void {
    if (!this.checkIsCheckedAll()) {
      this.manageCourierController.manageCourierData.forEach(courier => {
        if (
          !this.controller.shuttleForm.shuttleCourier.find(
            shuttleCourier => shuttleCourier.courierId === courier.courierId
          )
        ) {
          this.controller.shuttleForm.shuttleCourier.push(courier)
        }
      })
    } else {
      this.manageCourierController.manageCourierData.forEach(courier => {
        this.controller.shuttleForm.shuttleCourier = this.controller.shuttleForm.shuttleCourier.filter(
          shuttleCourier => shuttleCourier.courierId !== courier.courierId
        )
      })
    }
  }

  public onCheck(courierId: number): void {
    if (!this.checkIsChecked(courierId)) {
      this.controller.shuttleForm.shuttleCourier.push(
        <ManageCourier>(
          this.manageCourierController.manageCourierData.find(
            courier => courier.courierId === courierId
          )
        )
      )
    } else {
      this.controller.shuttleForm.shuttleCourier = this.controller.shuttleForm.shuttleCourier.filter(
        courier => courier.courierId !== courierId
      )
    }
  }

  public checkIsChecked(courierId: number): boolean {
    return Boolean(
      this.controller.shuttleForm.shuttleCourier.find(
        courier => courier.courierId === courierId
      )
    )
  }

  public checkIsCheckedAll(): boolean {
    let result: boolean[] = []

    this.manageCourierController.manageCourierData.forEach(courier => {
      result.push(
        Boolean(
          this.controller.shuttleForm.shuttleCourier.find(
            shuttleCourier => shuttleCourier.courierId === courier.courierId
          )
        )
      )
    })

    return !result.includes(false)
  }

  public removeAllCourierId(): void {
    this.controller.shuttleForm.shuttleCourier = []
  }

  public onChangePerPage(perPage: number): void {
    this.parameters.perPage = perPage
    this.fetchCourierList(true)
  }

  public backToForm(): void {
    this.$router.replace({
      name: this.isEditPage
        ? 'ShuttleManagementEditForm'
        : 'ShuttleManagementCreateForm',
    })
  }

  public onTableSort(sortType: CourierSortFields): void {
    switch (sortType) {
      case CourierSortFields.COURIER_NAME:
        if (this.selectedSort === CourierSortType.COURIER_NAME_ASC) {
          this.selectedSort = CourierSortType.COURIER_NAME_DESC
        } else if (this.selectedSort === CourierSortType.COURIER_NAME_DESC) {
          this.selectedSort = null
        } else {
          this.selectedSort = CourierSortType.COURIER_NAME_ASC
        }
        break
      case CourierSortFields.COURIER_PHONE_NUMBER:
        if (this.selectedSort === CourierSortType.COURIER_PHONE_NUMBER_ASC) {
          this.selectedSort = CourierSortType.COURIER_PHONE_NUMBER_DESC
        } else if (
          this.selectedSort === CourierSortType.COURIER_PHONE_NUMBER_DESC
        ) {
          this.selectedSort = null
        } else {
          this.selectedSort = CourierSortType.COURIER_PHONE_NUMBER_ASC
        }
        break
    }
    this.fetchCourierList(true)
  }

  private headerCellMapper(
    title: string | number,
    colWidth: string
  ): IHeaderCell {
    return {
      title: title,
      customStyle: {
        minWidth: colWidth,
        maxWidth: colWidth,
        wordBreak: 'break-word',
      },
    }
  }

  private tableCellMapper(
    value: string | number | Date | string[],
    colWidth: string,
    isDefault = false,
    routes?: string
  ): ITableCell {
    return {
      value,
      isDefault,
      routes,
      customStyle: {
        maxWidth: colWidth,
        minWidth: colWidth,
      },
    }
  }

  private setFloatingShadow(): void {
    if (
      window.innerHeight + window.scrollY >=
      document.body.getBoundingClientRect()['height']
    ) {
      this.$el.querySelector('.floating-button')?.classList.remove('shadow')
    } else {
      this.$el.querySelector('.floating-button')?.classList.add('shadow')
    }
  }

  @Watch('manageCourierController.manageCourierData')
  onManageCourierDataChanged(data: ManageCourier[]): void {
    this.listData = data.map(courier => [
      this.tableCellMapper(<number>courier.courierId, '92px'),
      this.tableCellMapper(<number>courier.courierId, '100px'),
      this.tableCellMapper(<string>courier.fullName, '152px'),
      this.tableCellMapper(<string>courier.phoneNumber, '182px'),
      this.tableCellMapper(<string>courier.courierTypeValid, '120px'),
      this.tableCellMapper(<string>courier.partnerName, '250px'),
      this.tableCellMapper(<string>courier.origin, '80px'),
      this.tableCellMapper(<number>courier.courierId, '120px'),
    ])
  }

  @Watch('routeController.letterCodes')
  onLetterCodesChanged(data: LetterCode[]): void {
    this.letterCodeOptions = data.map(route => {
      return {
        label: <string>route.lc,
        value: <string>route.lc,
      }
    })
  }

  beforeDestroy(): void {
    window.removeEventListener('scroll', this.setFloatingShadow)
  }
}
