function objectWithoutProperties (obj, exclude) { var target = {}; for (var k in obj) if (Object.prototype.hasOwnProperty.call(obj, k) && exclude.indexOf(k) === -1) target[k] = obj[k]; return target; }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"relative"},[_c('h1',{staticClass:"text-xl font-semibold text-black"},[_vm._v("Tambah Kurir")]),_c('div',{staticClass:"flex flex-row gap-5 mt-8 w-2/3"},[_c('div',{staticClass:"flex flex-col gap-2 w-1/2"},[_c('span',{staticClass:"label required"},[_vm._v("Cari")]),_c('TextInput',{attrs:{"data-testid":"shuttle-form-add-courier__search-input","type":"search","placeholder":"Nama Kurir, No, HP, Kurir ID"},on:{"input":_vm.onSearch},model:{value:(_vm.parameters.search),callback:function ($$v) {_vm.$set(_vm.parameters, "search", $$v)},expression:"parameters.search"}})],1),_c('div',{staticClass:"flex flex-col gap-2 w-40"},[_c('span',{staticClass:"label"},[_vm._v("3LC")]),_c('DropdownSelect',{attrs:{"data-testid":"shuttle-form-add-courier__letter-code-dropdown","options":_vm.letterCodeOptions,"placeholder":"Piilih 3LC"},on:{"input":_vm.onFilter},model:{value:(_vm.parameters.letterCode),callback:function ($$v) {_vm.$set(_vm.parameters, "letterCode", $$v)},expression:"parameters.letterCode"}})],1)]),_c('div',{staticClass:"py-3 add-courier-table"},[_c('DataTableV2',{attrs:{"headers":_vm.headers,"dataItems":_vm.listData},scopedSlots:_vm._u([{key:"header-0",fn:function(){return [_c('div',{staticClass:"flex flex-row gap-5"},[_c('CheckBox',{attrs:{"data-testid":"shuttle-management-add-courier__check-all","isChecked":_vm.checkIsCheckedAll()},on:{"click":_vm.onCheckAll}}),_c('span',{staticClass:"text-sm font-semibold text-gray-4"},[_vm._v("No")])],1)]},proxy:true},{key:"header-2",fn:function(ref){
var rest = objectWithoutProperties( ref, [] );
var data = rest;
return [_c('div',{staticClass:"flex flex-row gap-5 items-center cursor-pointer",attrs:{"data-testid":"shuttle-management-add-courier__table-sort-courier-name"},on:{"click":function($event){return _vm.onTableSort(_vm.enumSortFields.COURIER_NAME)}}},[_c('span',{staticClass:"w-24 sort-field"},[_vm._v(_vm._s(data.data.title))]),_c('div',{staticClass:"flex flex-col table-sort"},[_c('div',{staticClass:"ascending-icon",class:{
                'selected-sort':
                  _vm.selectedSort === _vm.enumSelectedSort.COURIER_NAME_ASC,
              }},[_c('AscendingIcon')],1),_c('div',{staticClass:"descending-icon",class:{
                'selected-sort':
                  _vm.selectedSort === _vm.enumSelectedSort.COURIER_NAME_DESC,
              }},[_c('AscendingIcon')],1)])])]}},{key:"header-3",fn:function(ref){
              var rest = objectWithoutProperties( ref, [] );
              var data = rest;
return [_c('div',{staticClass:"flex flex-row gap-5 items-center cursor-pointer",attrs:{"data-testid":"shuttle-management-add-courier__table-sort-courier-phone-number"},on:{"click":function($event){return _vm.onTableSort(_vm.enumSortFields.COURIER_PHONE_NUMBER)}}},[_c('span',{staticClass:"w-32 sort-field"},[_vm._v(_vm._s(data.data.title))]),_c('div',{staticClass:"flex flex-col table-sort"},[_c('div',{staticClass:"ascending-icon",class:{
                'selected-sort':
                  _vm.selectedSort === _vm.enumSelectedSort.COURIER_PHONE_NUMBER_ASC,
              }},[_c('AscendingIcon')],1),_c('div',{staticClass:"descending-icon",class:{
                'selected-sort':
                  _vm.selectedSort === _vm.enumSelectedSort.COURIER_PHONE_NUMBER_DESC,
              }},[_c('AscendingIcon')],1)])])]}},{key:"0",fn:function(ref){
              var rest = objectWithoutProperties( ref, [] );
              var data = rest;
return [_c('div',{staticClass:"flex flex-row gap-5"},[_c('CheckBox',{attrs:{"data-testid":("shuttle-management-add-courier__check-" + (data.index)),"isChecked":_vm.checkIsChecked(data.data.value)},on:{"click":function () { return _vm.onCheck(data.data.value); }}}),_c('span',{staticClass:"text-sm text-gray-4"},[_vm._v(_vm._s(data.index + 1 + _vm.parameters.perPage * (_vm.parameters.page - 1)))])],1)]}},{key:"7",fn:function(ref){
              var rest = objectWithoutProperties( ref, [] );
              var data = rest;
return [_c('div',{staticClass:"text-sm font-semibold text-bgPrimary cursor-pointer",attrs:{"data-testid":("shuttle-management-add-courier__add-" + (data.index))},on:{"click":function () { return _vm.onCheck(data.data.value); }}},[_vm._v(" Tambah ")])]}}])})],1),_c('PaginationNav',{staticClass:"mb-8 mt-2",attrs:{"data-testid":"shuttle-management-add-courier__pagination","page":_vm.parameters.page,"perPage":_vm.parameters.perPage,"lang":"id","enablePerPageOption":true,"perPageOptions":_vm.perPageOptions,"totalItem":_vm.manageCourierController.paginationData.totalItem},on:{"input":function () {
        _vm.fetchCourierList()
      },"onChangeOption":_vm.onChangePerPage},model:{value:(_vm.parameters.page),callback:function ($$v) {_vm.$set(_vm.parameters, "page", $$v)},expression:"parameters.page"}}),_c('div',{staticClass:"sticky bottom-0 h-15 floating-button bg-white"},[_c('div',{staticClass:"flex flex-row justify-between w-full"},[_c('div',{staticClass:"flex flex-row gap-2 items-center ml-1"},[_c('CheckBox',{attrs:{"data-testid":"shuttle-management-add-courier__remova-all-check","isChecked":Boolean(_vm.controller.shuttleForm.shuttleCourier.length)},on:{"click":_vm.removeAllCourierId}}),_c('span',{staticClass:"text-sm font-semibold text-bgMatterhorn mt-1"},[_vm._v(_vm._s(_vm.controller.shuttleForm.shuttleCourier.length)+" Kurir Terpilih")])],1),_c('Button',{staticClass:"text-sm font-semibold",attrs:{"data-testid":"shuttle-management-add-courier__add-courier","disabled":!Boolean(_vm.controller.shuttleForm.shuttleCourier.length)},on:{"click":function () {
            _vm.isModalConfirmVisible = true
          }}},[_vm._v("Tambah")])],1)]),_c('ModalConfirm',{attrs:{"data-testid":"shuttle-management-add-courier__modal-confirm","visible":_vm.isModalConfirmVisible,"title":"Tambah Kurir ke Dalam Shuttle?","description":"Pastikan kurir yang Anda tambah sudah sesuai","cancelBtnLabel":"Cek Ulang","actionBtnLabel":"Ya, Tambah"},on:{"action":_vm.backToForm,"cancel":function () {
        _vm.isModalConfirmVisible = false
      }}}),(_vm.manageCourierController.isLoading)?_c('LoadingOverlay'):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }